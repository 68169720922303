import React from 'react';

export interface IQuizContext {
  controllerIsReady: boolean;
  initController: Function;
  sendSubmission: Function;
  lastSubmissionId: string;
}

export const quizDefaultValue = {
  controllerIsReady: false,
  initController: () => {},
  sendSubmission: () => {},
  lastSubmissionId: null,
};

export const QuizContext = React.createContext<IQuizContext>(quizDefaultValue);

export function useQuiz(): IQuizContext {
  return React.useContext(QuizContext);
}

export const QuizConsumer = QuizContext.Consumer;
